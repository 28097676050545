export const popular_data = [
    {
        title: 'Home & Family',
        verticals: [
            { 
                vertical: 'Home Security', 
                image: 'https://assets.bluekeel-api.com/10best/home_security.png',
                thumb: 'https://assets.bluekeel-api.com/10best/home_security-thumb.png',
                vertical_items: [
                    {
                        company_id: '001',
                        name: 'Protect America',
                        reviews: [
                            {
                                review_id: '111',
                                reviewer: 'Mr. Smith',
                                value: '4',
                                comment: 'Good but not great'
                            },
                            {
                                review_id: '112',
                                reviewer: 'Mr. Mith',
                                value: '5',
                                comment: 'Great'
                            },
                            {
                                review_id: '113',
                                reviewer: 'Mr. Smithy',
                                value: '5',
                                comment: 'Super great'
                            },
                        ]
                    },
                    {
                        company_id: '006',
                        name: 'Frontpoint',
                        reviews: [
                            {
                                review_id: '114',
                                reviewer: 'Mrs. Smith',
                                value: '4',
                                comment: 'Good but not great'
                            },
                            {
                                review_id: '115',
                                reviewer: 'Mrs. Mith',
                                value: '4',
                                comment: 'Great'
                            },
                            {
                                review_id: '116',
                                reviewer: 'Mrs. Smithy',
                                value: '3',
                                comment: 'Super not great'
                            },
                        ]
                    }
                ]
            },
            { 
                vertical: 'Medical Alerts', 
                image: 'https://assets.bluekeel-api.com/10best/medical_alerts.png',
                thumb: 'https://assets.bluekeel-api.com/10best/medical_alerts-thumb.png',
                vertical_items: [
                    {
                        company_id: '002',
                        name: 'Medical Company',
                        reviews: [
                            {
                                review_id: '117',
                                reviewer: 'Karen',
                                value: '2',
                                comment: 'I want to see the manager!'
                            }
                        ]
                    }
                ]
            },
            { 
                vertical: 'Solar Energy', 
                image: 'https://assets.bluekeel-api.com/10best/solar_energy.png',
                thumb: 'https://assets.bluekeel-api.com/10best/solar_energy-thumb.png',
                vertical_items: [
                    {
                        company_id: '003',
                        name: 'Namaste',
                        reviews: [
                            {
                                review_id: '118',
                                reviewer: 'Karen',
                                value: '2',
                                comment: 'I want to see the manager!'
                            }
                        ]
                    }
                ]
            },
        ]
    },
    {
        title: 'Debt Relief',
        verticals: [
            { 
                vertical: 'Tax Debt', 
                image: 'https://assets.bluekeel-api.com/10best/tax_debt.png',
                thumb: 'https://assets.bluekeel-api.com/10best/tax_debt-thumb.png',
                vertical_items: [
                    {
                        company_id: '004',
                        name: 'Tax Debt Helpers',
                        reviews: [
                            {
                                review_id: '119',
                                reviewer: 'Karen',
                                value: '2',
                                comment: 'I want to see the manager!'
                            }
                        ]
                    }
                ]
            },
            { 
                vertical: 'Identity Theft', 
                image: 'https://assets.bluekeel-api.com/10best/identity_theft.png',
                thumb: 'https://assets.bluekeel-api.com/10best/identity_theft-thumb.png',
                vertical_items: [
                    {
                        company_id: '005',
                        name: 'Identity Matters',
                        reviews: [
                            {
                                review_id: '120',
                                reviewer: 'Karen',
                                value: '2',
                                comment: 'I want to see the manager!'
                            }
                        ]
                    }
                ]
            },
            { 
                vertical: 'Student Debt', 
                image: 'https://assets.bluekeel-api.com/10best/student_debt.png',
                thumb: 'https://assets.bluekeel-api.com/10best/student_debt-thumb.png',
                vertical_items: [
                    {
                        company_id: '007',
                        name: 'Learn about Debt',
                        reviews: [
                            {
                                review_id: '121',
                                reviewer: 'Karen',
                                value: '2',
                                comment: 'I want to see the manager!'
                            }
                        ]
                    }
                ]
            },
        ]
    },
    {
        title: 'Financial',
        verticals: [
            { 
                vertical: 'Business Loans', 
                image: 'https://assets.bluekeel-api.com/10best/business_loans.png',
                thumb: 'https://assets.bluekeel-api.com/10best/business_loans-thumb.png',
                vertical_items: [
                    {
                        company_id: '008',
                        name: 'Invest in Yourself',
                        reviews: [
                            {
                                review_id: '122',
                                reviewer: 'Karen',
                                value: '2',
                                comment: 'I want to see the manager!'
                            }
                        ]
                    }
                ]
            },
            { 
                vertical: 'Credit Repair', 
                image: 'https://assets.bluekeel-api.com/10best/credit_repair.png',
                thumb: 'https://assets.bluekeel-api.com/10best/credit_repair-thumb.png',
                vertical_items: [
                    {
                        company_id: '009',
                        name: 'Crdit Sux',
                        reviews: [
                            {
                                review_id: '123',
                                reviewer: 'Karen',
                                value: '2',
                                comment: 'I want to see the manager!'
                            }
                        ]
                    }
                ]
            },
            { 
                vertical: 'Personal Loans', 
                image: 'https://assets.bluekeel-api.com/10best/personal_loans.png',
                thumb: 'https://assets.bluekeel-api.com/10best/personal_loans-thumb.png',
                vertical_items: [
                    {
                        company_id: '010',
                        name: 'GetPaper',
                        reviews: [
                            {
                                review_id: '124',
                                reviewer: 'Karen',
                                value: '2',
                                comment: 'I want to see the manager!'
                            }
                        ]
                    }
                ]
            },
        ]
    },
];