import React from 'react';
import Navbar from './Navbar';
import Radium from 'radium';
import Banner from './Banner';
import Popular from './Popular';
import Featured from './Featured';
import Suggest from './Suggest';
import Footer from './Footer';

const MainLayout = Radium(() => {
	return (
        <Navbar>
            <Banner />
            <Popular />
            <Featured />
            <Suggest />
            <Footer />
        </Navbar>
	);
});

export default MainLayout;