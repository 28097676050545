import React, { useRef, useState, useEffect } from 'react';
import styles from './ImageContainer.css.js';
import Radium from 'radium';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Image from './Image';

const ImageContainer = Radium((props) => {
    const { src, thumb, alt, overlay } = props;
    const ref = useRef();
    const [ isVisible, setIsVisible ] = useState(false);
    const [ imageBg, setImageBg ] = useState(null);
    
    useIntersectionObserver({
        target: ref,
        onIntersect: ([{ isIntersecting }], observerElement) => {
            if (isIntersecting) {
                setIsVisible(true);
                observerElement.unobserve(ref.current);
            }
        }
    });


    useEffect(() => {
        if(!imageBg) {
            let bgImg = document.createElement('IMG');
            bgImg.onload = () => {
                setImageBg('linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + bgImg.src + ')');
            };
            bgImg.src = src;
        };

    }, [imageBg]);

    return (
        <div 
            ref={ref}
            style={styles.imageContainer} 
        >
            {isVisible && (
                <Image
                    alt={alt}
                    isLoaded={!!imageBg}
                    thumb={thumb}
                    imageBg={imageBg}
                    overlay={overlay}
                />
                
            )}
        </div>
    );
});

export default ImageContainer;