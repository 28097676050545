import React from 'react';
import Lottie from 'react-lottie';
import animationData from './bubble.json';
import styles from './LoadingBubbles.css.js';
import Radium from 'radium';

const LoadingBubbles = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div style={styles.loadContainer}>
            <Lottie
                style={styles.bubbles}
                options={defaultOptions}
                width={200}
            />
        </div>
    );
}

export default Radium(LoadingBubbles);