const styles = {
    imageContainer: {
        position: 'relative',
        overflow: 'hidden',
        background: 'rgba(0,0,0,0.05)'
    },
    image: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
    },
    full: {
        transition: 'opacity 400ms ease 0ms',
        
    },
    thumb: {
        filter: 'blur(20px)',
        transform: 'scale(1.1)',
        transition: 'visibility 0ms ease 400ms',
    },
    overlayImage: {
        width: '300px',
        height: '200px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '2rem',
    }
};

export default styles;