import dog from '../../assets/images/dog.png';

const styles = {
    hero: {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${dog})`,
        height: '50%',
        width: '100vw',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'absolute',
    },
    textContainer: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    headerText: {
        fontSize: '4rem',
        whiteSpace: 'nowrap'
    },
    subText: {
        fontSize: '1.2rem',
        width: '65%',
    },
    buttonGroup: {
        display: 'inline-flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '80%'
    },
    select: {
        marginRight: '20px'
    },
    location: {
        marginRight: '20px'
    },
    go: {
        width: '100%'
    }
};

export default styles;