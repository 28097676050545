import React, { cloneElement, useContext } from 'react';
import { AppContext } from '../../../context';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import styles from './Navbar.css.js';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Radium from 'radium';
import icon_logo from '../../assets/logos/logo.png';

const ElevationScroll = (props) => {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window,
    });

    return cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
};

const Navbar = Radium(({ children, ...props }) => {
    let history = useHistory();
    const { dispatchApp } = useContext(AppContext);

    const drawerClick = () => {
        dispatchApp({ type: 'TOGGLE_DRAWER' });
    };

    const goHome = () => {
        dispatchApp({ type: 'RESTART_SEARCH' });
        window.scrollTo(0, 0);
		history.push('/');
    };
    
    return (
        <>
        <ElevationScroll {...props}>
            <AppBar position='fixed' key='navbar_key' style={styles.navbar}>
                <Toolbar style={styles.toolbar}>
                    <div key='brand_key' style={styles.navContent}>
                        <img 
                            src={icon_logo} 
                            alt='navbar_brand' 
                            onClick={goHome}
                            style={styles.brand}
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
        <Toolbar style={{minHeight: '50px', height: '60px'}} />
        {children}
        </>
    );
});

export default Navbar;