const styles = {
    main: {
        position: 'relative',
        top: '50vh',
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0'
    },
    headerContent: {
        width: '100%',
        display: 'inline-flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerSpace: {
        width: '40%',
        border: '1px solid rgba(0,0,0,0.1)',
    },
    header: {
        width: '20%',
        fontSize: '1.5rem'
    },
    green: {
        color: 'rgb(62,223,129)'
    },
    contentRows: {
        width: '100%',
        maxWidth: '1000px',
        // minHeight: '1000px',
        margin: '25px 0',
    }
};

export default styles;