import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context';
import Radium from 'radium';
import LoadingBubbles from './Loaders/LoadingBubbles';
import MainLayout from './Layout';
import pingHitStreet from '../utils/hit_street';
import styles from './Style';

const App = Radium(() => {
    const [ myURL ] = useState(new URL(window.location.href));
    const { dispatchTracking, trackingState } = useContext(AppContext);

    const setUserContext = async() => {
        const topProps = {
            hsid: myURL.searchParams.get('hsid') || 0,
            pid: myURL.searchParams.get('pid') || trackingState.pid,
            sid: myURL.searchParams.get('sid') || trackingState.sid,
            oid: myURL.searchParams.get('oid') || trackingState.oid,
            uid: myURL.searchParams.get('uid') || trackingState.uid,
            eid: myURL.searchParams.get('eid') || trackingState.eid,
        };
        const trackedHsid = await pingHitStreet(topProps);

        const tracking = {
            ...topProps,
            hsid: trackedHsid,
            se: myURL.searchParams.get('se') || trackingState.se,
            kwd: myURL.searchParams.get('kwd') || trackingState.kwd,
            pacid: myURL.searchParams.get('pacid') || trackingState.pacid,
            pt1: myURL.searchParams.get('pt1') || trackingState.pt1,
            pt2: myURL.searchParams.get('pt2') || trackingState.pt2,
            gclid: myURL.searchParams.get('gclid') || trackingState.gclid,
            email: myURL.searchParams.get('email') || trackingState.email,
            vertical: myURL.searchParams.get('vertical') || trackingState.vertical,
            type: myURL.searchParams.get('type') || trackingState.type
        };
        dispatchTracking({ type: 'USER_ARRIVED', payload: tracking });
    };

    useEffect(() => {
        setUserContext();

    },[])

    return (
        <div style={styles.app}>
            {trackingState.hsid !== 0 ? <MainLayout /> : <LoadingBubbles />}
        </div>
    )
});

export default App;