import React from 'react';
import styles from './Footer.css.js';
import Radium from 'radium';
import bk_logo from '../../assets/logos/bk_logo.png';
import social from '../../assets/images/social.png';
import foot_logo from '../../assets/logos/foot_logo.png';

const Footer = Radium(() => {
    return (
        <div style={styles.main}>
            <div style={styles.footerContent}>
                <div style={styles.footerTop}>
                    <div style={styles.topLeft}>
                        <img 
                            src={foot_logo} 
                            alt='navbar_brand'
                            style={styles.brand}
                        />
                        <p style={styles.footText}>
                            We are focused on creating the ultimate consumer experience. We believe that when it comes to making decisions about products and services, simple is better. That's why we've taken the time to provide clear anf easy-to-read rankings, facts, and breakdowns of each company.
                        </p>
                    </div>
                    <div style={styles.topRight}>
                        <div key='column-1' style={styles.linkColumn}>
                            <b>Links</b>
                            <div>
                                <div style={styles.link}>link one</div>
                                <div style={styles.link}>link two</div>
                                <div style={styles.link}>link three</div>
                                <div style={styles.link}>link four</div>
                                <div style={styles.link}>link five</div>
                            </div>
                        </div>
                        <div key='column-2' style={styles.linkColumn}>
                            <b>Links</b>
                            <div>
                                <div style={styles.link}>link one</div>
                                <div style={styles.link}>link two</div>
                                <div style={styles.link}>link three</div>
                                <div style={styles.link}>link four</div>
                                <div style={styles.link}>link five</div>
                            </div>
                        </div>
                        <div key='column-3' style={styles.linkColumn}>
                            <b>Links</b>
                            <div>
                                <div style={styles.link}>link one</div>
                                <div style={styles.link}>link two</div>
                                <div style={styles.link}>link three</div>
                                <div style={styles.link}>link four</div>
                                <div style={styles.link}>link five</div>
                            </div>
                        </div>
                        <div key='column-4' style={styles.linkColumn}>
                            <b>Links</b>
                            <div>
                                <div style={styles.link}>link one</div>
                                <div style={styles.link}>link two</div>
                                <div style={styles.link}>link three</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.footerBottom}>
                    <div>
                        <span>Powered by</span>
                        <img alt='bk_logo' src={bk_logo} style={styles.bkLogo} />
                    </div>
                    <img alt='socials' src={social} style={styles.social} />
                </div>
            </div>
        </div>
    )
});

export default Footer;
