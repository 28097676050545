const styles = {
    main: {
        position: 'relative',
        top: '50vh',
        display: 'flex',
        flexDirection: 'column',
        width: '800px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '30px 0 80px 0'
    },
    text: {
        padding: '0 100px 40px 100px',
        color: 'rgba(0,0,0,0.5)',
        fontSize: '1.25rem'
    },
    form: {
        display: 'inline-flex',
        justifyContent: 'center',
        width: '80%'
    },
    submit: {
        width: '250px'
    }
};

export default styles;