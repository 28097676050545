import React from 'react';
import styles from './Banner.css.js';
import Radium from 'radium';
import { FormSelect, Button, FormInput } from 'shards-react';

const Banner = Radium(() => {
    const handleSubmit = () => {
        console.log('submit!')
    };

    return (
        <div style={styles.hero}>
            <div style={styles.textContainer}>
                <div style={styles.headerText}>Find the best of what you're looking for.</div>
                <p style={styles.subText}>Thousands of customer reviews. Hundreds of detailed data points. Our independent research will help you find a company you can trust.</p>
                <div style={styles.buttonGroup} onSubmit={handleSubmit}>
                    <FormSelect style={styles.select}>
                        <option value='first'>Category</option>
                        <option value='second'>This is the second option.</option>
                        <option value='third'>This option is disabled. </option>
                    </FormSelect>
                    <FormInput placeholder='Location' style={styles.location}/>
                    <Button theme='success' style={styles.go}>Go</Button>
                </div>
            </div>
        </div>
    )
});

export default Banner;
