import React from 'react';
import styles from './Featured.css.js';
import Radium from 'radium';

const Featured = Radium(() => {
    return (
        <div style={styles.main}>
            <div style={styles.headerContent}>
                <div style={styles.headerSpace}></div>
                <div style={styles.header}>Featured <span style={styles.green}>Categories</span></div>
                <div style={styles.headerSpace}></div>
            </div>
            <div style={styles.contentRows}></div>
        </div>
    )
});

export default Featured;