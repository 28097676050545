import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './App';
import { AppContextProvider } from '../context';
import useApolloClient from '../hooks/useApolloClient';
import { ApolloProvider } from '@apollo/client';
import { StyleRoot, Style } from 'radium';
import normalize from 'radium-normalize';
// fontawesome
import IconLibrary from '../utils/IconLibrary';
IconLibrary();

const history = createBrowserHistory();

const Global = () => {

	// const apolloUri = 'https://services.bluekeel-api.com/graphql';
	// const apolloUri = 'https://d-bkizqns6rk.execute-api.us-east-1.amazonaws.com/services/graphql';
	const apolloUri = 'https://cbvb02ezl4.execute-api.us-east-1.amazonaws.com/prod/graphql';
	// const apolloUri = 'https://d234ckldiolt4m.cloudfront.net/services/graphql';
	// const apolloUri = 'https://uqwaw9keg3.execute-api.us-east-1.amazonaws.com/services/graphql';
	// const apolloTest = 'https://cypmxq177b.execute-api.us-east-1.amazonaws.com/test/graphql';
	// const apolloUri = 'http://localhost:4000/graphql';

	const client = useApolloClient(apolloUri);

	return (
        <Router history={history}>
            <ApolloProvider client={client}>
                <AppContextProvider>
                    <StyleRoot>
                        <Style rules={normalize} />                        
                        <App />
                    </StyleRoot>
                </AppContextProvider>
            </ApolloProvider>
        </Router>
	);
};


export default Global;