import { InMemoryCache, ApolloClient, ApolloLink, from, HttpLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';

const useApolloClient = (uri) => {

    const loggerLink = new ApolloLink((operation, forward) => {
        console.log(`Started: GraphQL Request: ${operation.operationName}`);
        operation.setContext({
            start: new Date()
        });
        return forward(operation).map((response) => {
            const responseTime = new Date() - operation.getContext().start;
            console.log(`Completed: GraphQL Response in: ${responseTime}`);
            return response;
        });
    });
    
    const retryLink = new RetryLink({
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true
        },
        attempts: {
            max: 5,
            retryIf: (error, operation) => {
                console.log('error occurred:', error);
                console.log('error operation:', operation);
                // const doNotRetryCodes = [500, 400];
                // return !!error && !doNotRetryCodes.includes(error.statusCode);
                return !!error
            },
        }
    });

    const reportErrors = (errorCallback) => new ApolloLink((operation, forward) => {
        const observable = forward(operation);
        // errors will be sent to the errorCallback
        observable.subscribe({ error: errorCallback })
        return observable;
    });

    const links = from([
		loggerLink,
		retryLink,
		reportErrors(console.error),
		new HttpLink({ uri })
	]);

    const client = new ApolloClient({
		link: links,
		cache: new InMemoryCache(),
		// connect to your application's Apollo Client in production
        connectToDevTools: true,
        defaultOptions: {
            watchQuery: {
                errorPolicy: 'all'
            },
            query: {
                errorPolicy: 'all'
            },
            mutate: {
                errorPolicy: 'all',
            },
        }
	});

    return client;
}

export default useApolloClient;