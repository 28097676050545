const styles = {
    navbar: {
        height: '50px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        boxShadow: '0 4px 6px -6px black',
        backgroundColor: '#fff'
    },
    toolbar: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
    },
    navContent: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1400px',
        height: '40px'
    },
    brand: {
        width: 'auto',
        height: '90px',
        position: 'absolute',
        top: '0',
        ':hover': {
            cursor: 'pointer'
        }
    },
};

export default styles;