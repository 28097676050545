import React from 'react';
import styles from './Popular.css.js';
import Radium from 'radium';
import PopularRow from './PopularRow';
import { popular_data } from './popular_data';

const Popular = Radium(() => {
    return (
        <div style={styles.main}>
            <div style={styles.headerContent}>
                <div style={styles.headerSpace}></div>
                <div style={styles.header}>Most Engaged <span style={styles.green}>Categories</span></div>
                <div style={styles.headerSpace}></div>
            </div>
            <div style={styles.contentRows}>
                <PopularRow rowData={popular_data[0]}/>
                <PopularRow rowData={popular_data[1]}/>
                <PopularRow rowData={popular_data[2]}/>
            </div>
        </div>
    )
});

export default Popular;