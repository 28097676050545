const styles = {
    main: {
        marginBottom: '25px'
    },
    titleContainer: {
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        padding: '5px 0 10px 0'
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginRight: '10px',
        fontSize: '0.7rem',
        color: 'black'
    },
    titleCount: {
        fontSize: '0.7rem',
        color: 'rgba(0,0,0,0.5)'
    },
    rowContainer: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '250px'
    },
    viewAll: {
        marginTop: '20px',
        color: 'rgba(58,91,188,0.8)',
        fontSize: '0.9rem'
    },
    rowItem: {
        height: '250px',
        width: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    itemTop: {
        height: '200px',
        width: '300px',
    },
    itemBottom: {
        height: '50px',
        width: '100%',
        backgroundColor: '#fff',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        MozBoxShadow: '0 0 3px 1px rgba(0,0,0,0.2)',
        WebkitBoxShadow: '0 0 3px 1pxrgba(0,0,0,0.2)',
        boxShadow: '0 0 3px 1px rgba(0,0,0,0.2)',
    },
    bottomLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: '10px'
    },
    bottomRight: {},
    button: {
        maxWidth: '45px',
        padding: '5px',
        borderLeft: '1px solid rgba(0,0,0,0.1)'
    },
    hover: {
        cursor: 'pointer',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
        fontSize: '1.6rem',
    }
};

export default styles;