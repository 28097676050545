import React, { useState } from 'react';
import styles from './PopularRow.css.js';
import Radium from 'radium';
import button from '../../../assets/images/button.png';
import ImageContainer from '../../../ImageContainer';

const PopularItem = Radium(({data, idx}) => {
    const [ isHovering, setHovering ] = useState(false);

    const hoverStyle = Object.assign({},
        styles['button'],
        isHovering && styles['hover']
    );

    return (
        <div style={styles.rowItem} key={`row-item-${idx}`} >
            <ImageContainer
                src={data.image}
                thumb={data.thumb}
                alt={`popular-item-${idx}`}
                overlay={data.vertical}
            />
            <div style={styles.itemBottom}>
                <div style={styles.bottomLeft}>{`${Math.floor((idx + 1) * 100 * Math.random())} Companies`}</div>
                <div 
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)} 
                style={styles.bottomRight}
                >
                    <img alt='go-butt' src={button} style={hoverStyle} />
                </div>
            </div>
        </div>
    )
});

export default PopularItem