import React, { Fragment } from 'react';
import styles from './ImageContainer.css.js';
import Radium from 'radium';

const Image = Radium((props) => {
    const { alt, thumb, imageBg, overlay, isLoaded } = props;

    const loadingStyle = Object.assign({},
        styles['image'],
        styles['thumb'],
        { visibility: isLoaded ? 'hidden' : 'visible' }
    );
    
    const loadedStyle = Object.assign({},
        styles['image'],
        styles['full'],
        { opacity: isLoaded ? 1 : 0 }
    );
    
    const loadingOverlay = Object.assign({},
        styles['overlayImage'],
        styles['thumb'],
        { backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${thumb})` },
        isLoaded && { visibility: 'hidden', height: '0' }
    );
    
    const loadedOverlay = Object.assign({},
        styles['overlayImage'],
        styles['full'],
        { backgroundImage: imageBg },
        { opacity: isLoaded ? 1 : 0 }
    );

    return (
        overlay === 'N/A' ? (
            <Fragment>
                <img
                    alt={alt}
                    src={thumb}
                    style={loadingStyle}
                />
                <img
                    style={loadedStyle}
                    alt={alt}
                    src={imageBg}
                />
            </Fragment>
        ) : (
            <Fragment>
                <div style={loadingOverlay}>
                    {overlay}
                </div>
                <div style={loadedOverlay}>
                    {overlay}
                </div>
            </Fragment>
        )
    );
});
export default Image;