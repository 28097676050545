import React from 'react';
import styles from './PopularRow.css.js';
import Radium from 'radium';
import PopularItem from './PopularItem';

const PopularRow = Radium(({ rowData }) => {

    return (
        <div style={styles.main}>
            <div style={styles.titleContainer}>
                <div style={styles.title}>{rowData.title}</div>
                <div style={styles.titleCount}>{`(${rowData.verticals.length})`}</div>
            </div>
            <div style={styles.rowContainer}>
                <PopularItem data={rowData.verticals[0]} idx={0} />
                <PopularItem data={rowData.verticals[1]} idx={1} />
                <PopularItem data={rowData.verticals[2]} idx={2} />
            </div>
            <div style={styles.viewAll}>
                View All
            </div>
        </div>
    )
});

export default PopularRow;