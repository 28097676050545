const styles = {
    main: {
        backgroundColor: '#4A4A4A',
        position: 'relative',
        top: '50vh',
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0',
        color: '#D2D2D2'
    },
    footerContent: {
        width: '100%',
        maxWidth: '1600px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    footerTop: {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'nowrap'
    },
    topLeft: {
        width: '40%',
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    brand: {
        height: '80px'
    },
    footText: {
        textAlign: 'left',
        width: '75%',
        fontSize: '1.1rem'
    },
    topRight: {
        width: '60%',
        minHeight: '200px',
        display: 'inline-flex',
        justifyContent: 'space-between',
        marginTop: '20px'
    },
    linkColumn: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    link: {
        width: '100%',
        margin: '10px',
        textAlign: 'left'
    },
    footerBottom: {
        borderTop: '1px solid #D2D2D2',
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 10px'
    },
    bkLogo: {
        marginLeft: '10px'
    },
    social: {}
};

export default styles;