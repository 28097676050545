import React from 'react';
import styles from './Suggest.css.js';
import Radium from 'radium';
import { Button, ButtonGroup, FormInput } from 'shards-react';

const Suggest = Radium(() => {
    return (
        <div style={styles.main}>
            <div style={styles.text}>
                We've covered many industries in detail, but we want to do more. If we haven't covered an industry or company you want to learn more about, let us know so we can put them on the fast track to review.
            </div>
            <ButtonGroup style={styles.form} onSubmit={() => console.log('submit!')}>
                <FormInput placeholder='Enter an industry of company name here' />
                <Button theme='success' style={styles.submit}>Suggest</Button>
            </ButtonGroup>
        </div>
    )
});

export default Suggest;
