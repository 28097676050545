import axios from 'axios';
const cancelToken = axios.CancelToken;
const source = cancelToken.source();

const buildHitStreetLink = (payload) => (
    'https://bkoffers.com/hitstreet/hit_count_hsid2.cfm?' +
        'offer_id=' + payload.oid + '&' +
        'program_id=' + payload.pid + '&' +
        'hsid=' + payload.hsid + '&' +
        'eid=' + payload.eid + '&' +
        'oid=' + payload.oid + '&' +
        'pid=' + payload.pid + '&' +
        'sid=' + payload.sid + '&' +
        'uid=' + payload.uid
);

const pingHitStreet = async(payload) => {
    try{
        const fetchLink = buildHitStreetLink(payload);
        const res = await axios({
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            url: fetchLink,
            cancelToken: source.token
        });
        if (res.status !== 200) {
            const backup = Date.now();
            console.log('Error occured on HitStreet; Fallback ClickID created:', backup);
            return backup;
        };
        if(typeof res.data === 'number') {
            console.log('New HSID fetched:', res.data);
            return res.data;
        };
        console.log('HSID passed in URL:', res);
        return payload.hsid;
        
    } catch(err) {
        if (axios.isCancel(err)) {
            return {status: 'failed', message: 'axios hitstreet request cancelled'};
        }
        return {status: 'failed', message: err};
    }
};

export default pingHitStreet;