const initialTrackingState = {
    oid: 50,
    pid: 666,
    eid: 'organic',
    sid: 7572,
    uid: null,
    hsid: null,
    ip_address: null,
    kwd: null,
    se: null,
    pacid: null,
    pt1: 'N/A',
    pt2: 'N/A',
    gclid: null,
    email: '',
    fname: '',
    lname: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    vertical: 'N/A',
    type: 'N/A'
};

const trackingStateReducer = (state, action) => {
    switch (action.type) {
        case 'USER_ARRIVED':
            let tracking = {
                oid: action.payload.oid,
                pid: action.payload.pid,
                eid: action.payload.eid,
                sid: action.payload.sid,
                uid: action.payload.uid,
                hsid: action.payload.hsid,
                se: action.payload.se,
                kwd: action.payload.kwd,
                pacid: action.payload.pacid,
                pt1: action.payload.pt1,
                pt2: action.payload.pt2,
                gclid: action.payload.gclid,
                email: action.payload.email,
                vertical: action.payload.vertical,
                type: action.payload.type,
            };
            return {
                ...state,
                ...tracking
            };

        case 'SET_PCH_USER':
            return {
                ...state,
                email: action.payload.email,
                fname: action.payload.fname,
                lname: action.payload.lname,
                address: action.payload.address,
                city: action.payload.city,
                state: action.payload.state,
                zip: action.payload.zip 
            };

        case 'HSID_FOUND':
            return {
                ...state,
                hsid: action.payload
            };

        case 'SET_EMAIL':
            return {
                ...state,
                email: action.payload
            };
            
        case 'LOCATION_FOUND':
            return {
                ...state,
                city: action.payload.city,
                state: action.payload.state,
                country: action.payload.country,
                zip: action.payload.zip,
                ip_address: action.payload.ip_address,
            };          
        
        case 'KEYWORD_SELECTED':
            return {
                ...state,
                kwd: action.payload
            };       

        case 'RESET':
            return initialTrackingState;        

        default:
            throw new Error(`Not supported action ${action.type}`);
    }
};

export {
    initialTrackingState,
    trackingStateReducer
};